import React from "react"
import BookletLayout from "../layouts/BookletLayout"
import { makeStyles, Grid, Typography, Button } from "@material-ui/core"

import SEO from "../components/seo"
import NotificationBox from "../components/NotificationBox"

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  textBox: {
    padding: "48px 48px 0px 48px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "220px",
  },
  button: {
    marginTop: "30px",
    width: "200px",
  },
  link: {
    textDecoration: `none`,
    color: "inherit",
  },
})

const Calendar = () => {
  const classes = useStyles()

  return (
    <BookletLayout>
      <div className={classes.wrapper}>
        <SEO title="Dashboard" />
        <h1>🐶 Welcome to WOOFCODE!</h1>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <NotificationBox title="📅 Next Lecture">
              <div className={classes.textBox}>
                <Typography variant="body1">
                  The final lecture for the Winter 2021 semester was at{" "}
                  <strong>1pm GMT</strong> on <strong>Tuesday 9th March</strong>
                  .
                </Typography>
                <a
                  className={classes.link}
                  href="https://zoom.us/j/93794690136?pwd=eGZGOUlYV1plL29Ed3hwWVhpczNjQT09"
                  target="_blank"
                >
                  <Button
                    className={classes.button}
                    size="medium"
                    variant="outlined"
                    color="primary"
                  >
                    Zoom Link
                  </Button>
                </a>
              </div>
            </NotificationBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <NotificationBox title="📢 Announcements">
              <div className={classes.textBox}>
                <Typography variant="body1">
                  If you're joining this course after it's already started, then
                  leave your email address{" "}
                  <a href="http://eepurl.com/hm6zGX" target="_blank">
                    here
                  </a>{" "}
                  to receive updates.
                </Typography>
              </div>
            </NotificationBox>
          </Grid>
        </Grid>
      </div>
    </BookletLayout>
  )
}

export default Calendar
