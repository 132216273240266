import React from "react"
import { makeStyles, Paper, Toolbar, Typography } from "@material-ui/core"

const useStyles = makeStyles({
  paper: {
    border: "2px solid #cccccc",
  },
  toolbar: {
    borderBottom: "2px solid #cccccc",
  },
})

const NotificationBox = ({ title, children }) => {
  const classes = useStyles()

  return (
    <Paper className={classes.paper} elevation={0}>
      <Toolbar className={classes.toolbar}>
        <Typography variant="h6">{title}</Typography>
      </Toolbar>
      <div>{children}</div>
    </Paper>
  )
}

export default NotificationBox
